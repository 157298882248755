.searchBox2{
   display: flex;
   width: 100%;
   max-width: 800px;
   background-color: antiquewhite;
}

.searchBox2 input[type='text']{
   width: calc(100% - 100px);
   height: 48px;
   border: 1px solid #cccccc;
   padding: 10px;
   border-right: 0;
   color:#333333;
}

.searchBox2 input[type='submit']{
   width: 100px;
   height: 48px;
   border: 0px solid #cccccc;
   background-color: darkorange;
   color:#ffffff;
   cursor: pointer;
}

.searchBox2 form{
   width: 100%;
}


.searchResultContainer{
   width: 100%;
   padding-top: 30px;
}

.searchResultList{
   display: flex;
   width: 100%;
   max-width: 800px;
   border: 1px solid #eeeeee;
   height: 100px;
   padding: 10px;
   line-height: 1;
   margin-bottom: 10px;
}

.searchResultList .image{
   width: 80px;
   height: 80px;
   background-color: #eeeeee;
   padding: 0;
   border-radius: 5px;
   overflow: hidden;
   background-image: url(searchError2.png);
   background-position: center;
   background-repeat: no-repeat;
}

.searchResultList .image img{
   width: 80px;
   height: 80px;
   border-radius: 5px;
}

.searchResultList .info{
   width: calc(100% - 80px);
   height: 80px;
   padding-left: 20px;
}

.searchResultList .info a{
   text-decoration: none;
   color: mediumvioletred;
   font-weight: bold;
}