li{
   list-style: none;
}
.searchBox{
   display: block;
   text-align: center;
   margin: auto;
   margin-top: 100px;
}
.searchBox form {

   width: 420px;
   padding: 10px;
   margin: auto;
}
.searchBox input[type='text']{
   width: 300px;
   height: 48px;
   border: 1px solid #cccccc;
   padding: 10px;
   border-right: 0;
   color:#333333;
}

.searchBox input[type='submit']{
   width: 100px;
   height: 48px;
   border: 0px solid #cccccc;
   background-color: darkorange;
   color:#ffffff;
   cursor: pointer;
}
.searchBox input[type='submit']:hover{
   opacity: 0.8;
}

.searchBox .resultWrapper{
   display: block;
   width: 420px;
   min-height: 50px;
   margin: auto;
   padding: 10px;
   padding-top: 0;
}

.searchBox .resultWrapper .resultData{
   /* background-color: aqua; */
   border-top: 3px solid #dddddd;
}

.searchBox .resultWrapper .resultData li{
   display: block;
   text-align: left;
   padding: 5px;
   cursor: pointer;
   border: 1px solid #dddddd;
   border-top: 0;
   color: #222222;
}

.searchBox .resultWrapper .resultData li:hover{
   background-color: #eeeeee;
}

.searchBox .resultWrapper .resultData li a{
   text-decoration: none;
   color: #222222;
   display: block;
   height: 100%;
}



@media only screen and (max-width: 600px) {
   .searchBox{
      width: 100%;
      overflow: hidden;
   }
   .searchBox form{
      padding: 5px 0;
      width: 380px;
   }

   .searchBox input[type='text']{
      width: 240px;
   }
   .searchBox input[type='button']{
      width: 60px;
   }
   .searchBox .resultWrapper{
      display: block;
      width: 360px;
   }
 }